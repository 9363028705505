import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Nowrap = styled('span')`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`

const Rating = ({ icon, ...props }) => (
  <p {...props}>
    Priemerné hodnotenie kurzu našimi absolventmi je{' '}
    <Nowrap>
      <strong>9,7 z 10</strong>
      {icon}
    </Nowrap>
  </p>
)

Rating.propTypes = {
  icon: PropTypes.node.isRequired,
}

export default Rating
